import rq from 'request-promise';
import config from '../config';
import axios from 'axios';
// Not working :(
// rq.defaults({
//   withCredentials: true
// });

class Api {

  getMessageFromError = (e) => {
    return e;
    if (typeof e.error != 'undefined') {
      return e.error;
    }
    return e.message;
  }

  /* ADMIN */

  uploadAdminImage = async (id, file) => {
    return new Promise((resolve, reject) => {
      var formData = new FormData();
      formData.append("file", file);

      try {
        const res = axios.post(`${config.adminApi.url}/api/admin/upload/${id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          withCredentials: true
        })
        .then((res) => {
          resolve(res.data.url);
        })
      } catch(e) {
        reject(e);
      }
    });
  }

  loginAdminWithToken = async (token) => {
    const me = this;
    return new Promise((resolve, reject) => {
      rq({
        uri: `${config.adminApi.url}/api/admin/auth/token/${token}`,
        withCredentials: true,
        method: 'POST'
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(me.getMessageFromError(err), err);
        })
    })
  }

  getAdminSurvey = async (id) => {
    const me = this;
    return new Promise((resolve, reject) => {
      rq({
        uri: `${config.adminApi.url}/api/admin/${id}`,
        withCredentials: true,
        transform: JSON.parse
      })
        .then((survey) => {
          resolve(survey);
        })
        .catch((err) => {
          reject(me.getMessageFromError(err), err);
        })
      ;
    });
  }

  saveSurvey = async (id, body) => {
    const me = this;
    return new Promise((resolve, reject) => {
      rq({
        uri: `${config.adminApi.url}/api/admin/${id}`,
        withCredentials: true,
        method: 'PUT',
        body,
        json: true
      }).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(me.getMessageFromError(err), err);
      });
    });
  }

  getBrands = async () => {
    const me = this;
    return new Promise((resolve, reject) => {
      rq({
        uri: `${config.adminApi.url}/api/admin/brands`,
        withCredentials: true,
        method: 'GET',
        json: true
      }).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(me.getMessageFromError(err), err);
      });
    });
  }

  /* CLIENT */

  getSurvey = async (slug, prid = null) => {
    const me = this;
    return new Promise((resolve, reject) => {
      rq({
        uri: `${config.adminApi.url}/api/survey/${slug}` + (prid ? `?postRequestId=${prid}` : '' ),
        withCredentials: true,
        transform: JSON.parse
      })
        .then((survey) => {
          resolve(survey);
        })
        .catch((err) => {
          reject(me.getMessageFromError(err), err);
        })
      ;
    });
  }

  getLoggedInUser = async () => {
    const me = this;
    return new Promise((resolve, reject) => {
      rq({
        uri: `${config.adminApi.url}/api/survey/user`,
        withCredentials: true,
        method: 'GET',
        json: true
      }).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(me.getMessageFromError(err), err);
      });
    });
  }

  loginUser = async ({email, password}) => {
    const me = this;
    return new Promise((resolve, reject) => {
      rq({
        uri: `${config.adminApi.url}/api/survey/login`,
        withCredentials: true,
        method: 'POST',
        json: true,
        body: {
          email,
          password
        }
      }).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(me.getMessageFromError(err), err);
      });
    });
  };

  saveSurveyPost = async (id, data, completed = false) => {
    const me = this;
    return new Promise((resolve, reject) => {
      rq({
        uri: `${config.adminApi.url}/api/survey/${id}/answer`,
        withCredentials: true,
        method: 'POST',
        json: true,
        body: {
          ...data,
          completed
        }
      }).then((res) => {
        resolve(res);
      }).catch((err) => {
        console.log(err);
        reject(me.getMessageFromError(err), err);
      });
    });
  };

  getPost = async (id) => {
    const me = this;
    return new Promise((resolve, reject) => {
      rq({
        uri: `${config.adminApi.url}/api/survey/post/${id}`,
        withCredentials: true,
        method: 'GET',
        json: true
      }).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(me.getMessageFromError(err), err);
      });
    });
  }
}

export default new Api();
