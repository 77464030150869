import {useEffect} from "react";
import api from './admin/services/Api';

export function SurveyCreatorLogger(props) {
  const surveyid = props.match.params.surveyid;
  const tkn = props.match.params.tkn;

  const loginAdmin = async () => {
    try {
      await api.loginAdminWithToken(tkn);
    } catch(e) {

    }
    document.location.href=`/survey-editor/${surveyid}`
  }

  useEffect(() => {
    console.log('beforeLogin admin')
    loginAdmin();
  }, [props.match.params.tkn])

  return null;
}
